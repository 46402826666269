import {BuchRepository} from "../repository/BuchRepository";

export default class BuchController {
    public buchList(game: string): any[] {
        const list = new BuchRepository().BucherList;
        const newList: any[] = []

        list.forEach(buch => {
            newList.push({...buch, route: buch.route.replace("buch", game)});
        });

        return newList;
    }

    public lektionList(game: string, buch: string): any[] {
        const list = new BuchRepository().getBuch(buch);
        const newList: any[] = []

        if (list) {
            Object.keys(list).forEach(key => {
                newList.push({name: key, route: key});
            })
        }

        return newList;
    }
}