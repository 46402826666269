import {Card} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import HomeState from "../../Interfaces/HomeState";

function ItemList({state}: { state: HomeState }) {
    return <>
        <div className="home-list row">
            {state.list.map((item, index) => {
                if (!item.route.length)
                    return (
                        <div className={'col-6 col-xs-6 col-xl-2 col-md-3 disabled'} key={`home-${index}`}>
                            <Card>
                                <Card.Body>
                                    <h2>{item.name}</h2>
                                </Card.Body>
                            </Card>
                        </div>
                    )

                return (
                    <div className={'col-6 col-xs-6 col-xl-2 col-md-3'} key={`home-${index}`}>
                        <NavLink to={item.route}>
                            <Card>
                                <Card.Body>
                                    <h2>{item.name}</h2>
                                </Card.Body>
                            </Card>
                        </NavLink>
                    </div>
                )
            })}
        </div>
    </>;
}

export default ItemList;