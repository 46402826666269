import SWA1 from "../data/starten-wir-a1.json";
import SWA2 from "../data/starten-wir-a2.json";
import pkg from "lodash";

export class BuchRepository {
    private Bucher = {
        'starten-wir-a1': SWA1,
        "starten-wir-a2": SWA2,
    }

    public BucherList = [
        {name: "STARTEN WIR! A1", route: "/buch/starten-wir-a1"},
        {name: "STARTEN WIR! A2", route: "/buch/starten-wir-a2"},
        {name: "STARTEN WIR! B1", route: ""},
        {name: "STARTEN WIR! B2", route: ""},
    ]

    public getBuch(key: string): object | null {
        return pkg.get(this.Bucher, key, null)
    }

    public getLektion(bucketName: string, lektionName: string): object | null {
        const buch = this.getBuch(bucketName);
        return pkg.get(buch, lektionName, null)
    }
}