import {createBrowserRouter} from "react-router-dom";
import Home from "./features/home/views/Home";
import Buch from "./features/buch/views/Buch";
import Errors from "./features/home/views/Errors";
import Lektion from "./features/buch/views/Lektion";
import Games from "./features/games/Games";

export default createBrowserRouter([
    {
        path: "/",
        element: <Home/>,
    },
    {
        path: "/:game",
        element: <Buch/>,
    },
    {
        path: "/:game/:buch",
        element: <Lektion/>,
    },
    {
        path: "/:game/:buch/:lektion",
        element: <Games/>,
    },
    {
        path: "*",
        element: <Errors/>,
    },
]);