import {BuchRepository} from "../../../buch/repository/BuchRepository";
import {NavLink} from "react-router-dom";
import {useState, useEffect} from "react";
import {concat, includes, shuffle, slice} from "lodash";
import MatchingPairsState from "../interfaces/MatchingPairsState";

export default function MatchingPairs({buch, lektion}: { buch: string, lektion: string }) {
    const [state, setState] = useState<MatchingPairsState>({
        size: 4,
        index: 0,
        words: {},
        answerIndex: null,
        activeList: [],
        pendingIndex: [],
        okIndex: [],
        errorIndex: []
    });

    useEffect(() => {
        const words = new BuchRepository().getLektion(buch as string, lektion as string);
        const de = slice(Object.keys(words as object), state.index, state.size * 2);
        const fa = slice(Object.values(words as object), state.index, state.size * 2);
        setState(prevState => ({
            ...prevState,
            words: words as any,
            activeList: shuffle(concat(de, fa))
        }));
    }, []);

    useEffect(() => {
        const size = state.size * state.size;
        const total = Object.keys(state.words).length;
        const newIndex = state.index + 2;

        if (state.okIndex.length > 0 && state.okIndex.length === state.activeList.length) {
            if (state.index === total) {
                window.confirm("Herzlichen Glückwunsch, die Worte dieser Lektion sind zu Ende!")
            } else {
                const de = slice(Object.keys(state.words), newIndex, newIndex + (state.size * 2));
                const fa = slice(Object.values(state.words), newIndex, newIndex + (state.size * 2));

                setState(prevState => ({
                    ...prevState,
                    activeList: shuffle(concat(de, fa)),
                    okIndex: []
                }))
            }
        }

    }, [state.okIndex]);

    const updateAnswerIndex = (item: string, index: number) => {
        const s1 = Object.keys(state.words).indexOf(item);
        if (s1 >= 0) {
            const answer = Object.values(state.words)[s1];
            setState(prevState => ({
                ...prevState,
                pendingIndex: concat(prevState.pendingIndex, index),
                answerIndex: prevState.activeList.indexOf(answer as string)
            }));
        } else {
            const s2 = Object.values(state.words).indexOf(item);
            const answer = Object.keys(state.words)[s2];
            setState(prevState => ({
                ...prevState,
                pendingIndex: concat(prevState.pendingIndex, index),
                answerIndex: prevState.activeList.indexOf(answer as string)
            }));
        }
    };

    const handleClick = (item: string, index: number) => {
        if (includes(state.okIndex, index) || includes(state.pendingIndex, index))
            return;

        if (state.answerIndex === index) {
            const newPendingIndex = concat(state.pendingIndex, index);
            setState(prevState => ({
                ...prevState,
                okIndex: concat(prevState.okIndex, newPendingIndex.slice(0, 2)),
                pendingIndex: newPendingIndex.slice(2),
                index: state.index + 2,
                answerIndex: null
            }));
            return;
        }

        if (state.answerIndex !== null && state.answerIndex !== index) {
            const newPendingIndex = concat(state.pendingIndex, index);
            setState(prevState => ({
                ...prevState,
                pendingIndex: prevState.pendingIndex.slice(2),
                errorIndex: concat(prevState.errorIndex, newPendingIndex),
                answerIndex: null
            }));

            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    errorIndex: prevState.errorIndex.slice(2),
                }));
            }, 1500);
            return;
        }

        updateAnswerIndex(item, index);
    };


    return (
        <div className="flex-d">
            <header className="home-header text-center py-4">
                <h1 className="text-white">Matching Pairs</h1>
            </header>
            <main className="home-main container">
                <strong className={"capitalize"}>
                    {buch.replaceAll("-", " ")} - {lektion} <br/>
                    ({Object.values(state.words).length} / {state.index})
                </strong>
                <div className="matching-pairs row mt-1">
                    {state.activeList.map((item: string, index) => {
                        const phonetic = item.split(" /")[1];
                        return (
                            <div key={`matching-pairs-${index}`}
                                 className={
                                     includes(state.okIndex, index) ? "active" :
                                         includes(state.errorIndex, index) ? "error" :
                                             includes(state.pendingIndex, index) ? "pending" : undefined
                                 }
                                 onClick={() => handleClick(item, index)}>
                                <div>
                                    <h2>{item.split(" /")[0]}</h2>
                                    {phonetic ? <small>/{phonetic}/</small> : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="m-2 d-flex justify-content-center align-items-center">
                    <NavLink to={`/matching-pairs/${buch}`} className="btn btn-sm btn-dark">Back</NavLink>
                </div>
            </main>
        </div>
    );
}
