import ItemList from "../../home/views/components/ItemList";
import {NavLink, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {Spinner} from "react-bootstrap";
import {get, has} from "lodash";
import Errors from "../../home/views/Errors";
import {BuchState} from "../interfaces/BuchState";
import BuchController from "../controller/BuchController";

export default function Buch() {
    const [state, setState] = useState<BuchState>({loading: true, list: []});
    let {game} = useParams();

    const titles = {"matching-pairs": "Matching Pairs"};

    useEffect(() => {
        if (has(titles, game as string)) {
            const newList = new BuchController().buchList(game as string);
            setState({loading: false, list: newList});
        }
    }, [])


    if (!has(titles, game as string))
        return <Errors/>

    if (state.loading)
        return (<div className={"loading"}><Spinner/></div>)

    return (
        <div className={"flex-d"}>
            <header className="home-header text-center py-4">
                <h1 className={"text-white"}>{get(titles, game as string, "") as string}</h1>
            </header>
            <main className="home-main container">
                <strong>Wählen Sie das gewünschte Buch aus</strong>
                <ItemList state={state}/>

                <div className={"m-2 d-flex justify-content-center align-items-center"}>
                    <NavLink to={"/"} className={"btn btn-sm btn-dark"}>Back</NavLink>
                </div>
            </main>
        </div>
    )
}