import HomeState from "../Interfaces/HomeState";
import ItemList from "./components/ItemList";

export default function Home() {
    const state: HomeState = {
        list: [
            {name: "Matching Pairs", route: "/matching-pairs"},
            {name: "Hangman", route: ""},
            {name: "Word Search", route: ""},
            {name: "Quiz Show", route: ""},
            {name: "Word Building", route: ""}
        ]
    }

    return (
        <div className={"flex-d"}>
            <header className="home-header text-center py-4">
                <h1 className={"text-white"}>Willkommen bei Besty.Top</h1>
            </header>
            <main className="home-main container">
                <strong>Wählen Sie das gewünschte Spiel aus</strong>
                <ItemList state={state}/>
            </main>
        </div>
    )
}