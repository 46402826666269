import {useParams} from "react-router-dom";
import MatchingPairs from "./matchingPairs/views/MatchingPairs";
import {BuchRepository} from "../buch/repository/BuchRepository";
import Errors from "../home/views/Errors";

export default function Games() {
    let {game, buch, lektion} = useParams();

    if (!new BuchRepository().getLektion(buch as string, lektion as string))
        return <Errors/>

    switch (game) {
        case "matching-pairs":
            return <MatchingPairs buch={buch as string} lektion={lektion as string}/>;
        default:
            return null;
    }
}