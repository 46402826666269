import {NavLink} from "react-router-dom";

export default function Errors({message = "404 NotFound!", status = 404}: { message?: string, status?: number }) {

    return (
        <div className={"errors"}>
            <h1>{status}</h1>
            <p>{message}</p>
            <NavLink to={"/"} className={"btn btn-sm btn-success"}>Zur Startseite</NavLink>
        </div>
    )
}